import React, { useRef } from "react";
import { styles } from "./NavbarDropdown.styles";
import { Icon } from "../../../elements/Icon";
import { DropdownItem } from "../NavbarItem/NavbarItem";
import { BackShadow } from "../../../elements/BackShadow";
import useClickAway from "../../../hooks/useClickAway";

interface IProps {
  dropdownItems?: DropdownItem[];
  anchorElement: { current: HTMLInputElement };
  menuTitle?: string;
  open: boolean;
  setOpen: (boolean) => void;
  customSubTitleElement?: React.ReactNode;
}

const NavbarDropdown: React.FC<IProps> = ({
  dropdownItems = [],
  anchorElement,
  menuTitle,
  open,
  setOpen,
  customSubTitleElement,
}) => {
  const ref = useRef();
  const refContent = useRef<HTMLDivElement>();
  const height = refContent.current?.clientHeight;

  const isDashboardArea = !!dropdownItems[0].testSelector;
  const handleClose = (event) => {
    if (anchorElement.current && anchorElement.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  useClickAway(ref, handleClose, !open);
  return (
    <div>
      <div ref={ref}>
        <div css={styles.dropdownBody(open, isDashboardArea, height)}>
          <div ref={refContent}>
            {menuTitle && <div css={styles.menuTitle}>{menuTitle}</div>}
            <div>{customSubTitleElement}</div>
            <div css={styles.itemsContainer}>
              {dropdownItems.map((subItem: DropdownItem) => (
                <a
                  css={styles.subMenuLink}
                  href={subItem.link}
                  onClick={subItem.click}
                  key={`dropdown-item-${menuTitle}-${subItem.label}`}
                  data-testid={subItem.testSelector}
                >
                  {subItem.icon && <Icon css={styles.iconWrap} icon={subItem.icon} />}
                  <div data-navitem={subItem.label}>{subItem.label}</div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      {open && <BackShadow />}
    </div>
  );
};

export default NavbarDropdown;
