import { css } from "@emotion/react";
import { colors, letterSpacings, mediaQuery, spacing } from "../../../core";

export const styles = {
  root: () =>
    css({
      display: "flex",
      gap: spacing.baseUnitMobile1,
      minWidth: "186px",
      padding: spacing.baseUnitMobile2,
      borderBottom: `1px solid ${colors.disabledGrey.hex}`,
      "& > div": {
        letterSpacing: letterSpacings.body.horse,
      },
      [mediaQuery.desktopOrLarger]: {
        padding: `${spacing.baseUnitMobile2} ${spacing.baseUnitMobile2} ${spacing.baseUnitMobile2} 0`,
      },
    }),
};
