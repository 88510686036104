import { mediaQuery } from "@carfax/web-ui/core";
import { css } from "@emotion/react";

export const styles = {
  headerLogo: () =>
    css({
      display: "block",
      cursor: "pointer",
      width: "134px",
      height: "25px",
      marginLeft: "10px",
      margin: 0,
      outline: "none",
      content: `url(/static/images/logo/logo-dark-mode.svg)`,
      [mediaQuery.desktopOrLarger]: {
        width: "163.5px",
        height: "30.5px",
        margin: 0,
      },
      [mediaQuery.smallerThanMobile]: {
        width: "28px",
        height: "28px",
        content: `url(/static/images/logo/logo-small.svg)`,
      },
    }),
};
