import { Link, useTranslation } from "app";

import { Routes } from "utils/config/routes";
import { styles } from "./HeaderLogo.style";

const HeaderLogo: React.FC = () => {
  const { t } = useTranslation(["common"]);
  return (
    <Link href={Routes.HOME} passHref>
      <img data-testid="carfax-logo" css={styles.headerLogo()} alt={t("header.logo.altText")} />
    </Link>
  );
};

export default HeaderLogo;
