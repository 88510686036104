import React from "react";
import { NavbarChild } from "../Navbar";
import { NavbarItemTopBar } from "../NavbarDecorators";
import { styles } from "./MobileNavContainer.styles";

type MobileNavContainerProps = {
  logo?: React.ReactNode;
  sideMenuButton?: React.ReactNode;
  withSeparator?: boolean;
  mobileNav?: boolean;
  mobileComponents?: React.ReactNode[];
  children: NavbarChild[];
};

const MobileNavContainer: React.FC<MobileNavContainerProps> = ({ sideMenuButton, logo, children }) => {
  return (
    <div css={styles.navigationWrapper}>
      <div css={styles.naviLinks}>
        <div css={styles.leftSiteNav}>
          {sideMenuButton}
          <div css={styles.logoStyles}>{logo}</div>
        </div>
        <ul css={styles.groupItems} suppressHydrationWarning>
          {children.map((el) => {
            return (
              <NavbarItemTopBar
                key={`top-bar-mobile-${el.props.title}`}
                name={el.props.title}
                testSelector={el.props.testSelector ? `${el.props.testSelector}-mobile` : null}
                isMobile={true}
              >
                {el}
              </NavbarItemTopBar>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default MobileNavContainer;
