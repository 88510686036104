import React, { useState } from "react";
import { DropdownItem, NavbarItemProps } from "..";
import { Icon, Icons } from "../../../../elements/Icon";
import { MegaMenuColumn } from "../../MegaMenu";
import { styles } from "../NavbarItem.styles";
import Collapsible from "../../../../elements/Collapsible/Collapsible";

type MobileNavbarItemProps = {
  isDropdown: boolean;
  anchorElement: React.MutableRefObject<any>;
};

export const MobileNavbarItem: React.FC<MobileNavbarItemProps & NavbarItemProps> = ({
  testSelector,
  isActive,
  isDropdown,
  link,
  onClick,
  title,
  dropdownItems,
  megaMenuColumns,
  anchorElement,
  setIsDrawerOpen,
  isDrawerOpen,
}) => {
  const [showSubmenuInSideMenu, setShowSubmenuInSideMenu] = useState(false);

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  return (
    <>
      <a
        data-testid={testSelector}
        className={isActive ? "active" : ""}
        ref={anchorElement}
        onClick={(e) => {
          setShowSubmenuInSideMenu(!showSubmenuInSideMenu);
          if (link) {
            toggleDrawer();
            onClick(e, link);
          }
        }}
        href={link}
      >
        <div ref={anchorElement} css={styles.menuItemWrapper}>
          {isDropdown && <Icon icon={Icons.arrowThin} css={styles.arrowSideMenuIcon(showSubmenuInSideMenu)} />}
          {title}
        </div>
      </a>
      {isDropdown && (
        <Collapsible show={showSubmenuInSideMenu}>
          <div key={`subitem-menu-container-${title}`} css={styles.menuItemWrapper}>
            {dropdownItems && (
              <ul>
                {dropdownItems.map((subItem: DropdownItem) => (
                  <li key={`subitem-side-menu-${subItem.label}`} css={styles.sideSubmenu()}>
                    <a href={subItem.link}>{subItem.label}</a>
                  </li>
                ))}
              </ul>
            )}
            {megaMenuColumns &&
              megaMenuColumns.map((column: MegaMenuColumn) => {
                return (
                  <div key={`subitem-side-megamenu-${column.title}`}>
                    {column?.title && <div css={styles.columnTitle}>{column.title}</div>}
                    <ul>
                      {column.items.map((item) => {
                        return (
                          <li key={`subitem-side-megamenu-item-${item.name}`} css={styles.sideSubmenu(item.isHidden)}>
                            <a
                              onClick={(e) => {
                                if (item.link) {
                                  toggleDrawer();
                                  item?.onClick(e, item.link || "/");
                                }
                              }}
                              hidden={item.isHidden}
                              href={item?.link || ""}
                              data-testid={item?.testSelector ? `${item.testSelector}-mobile` : null}
                              className={item?.isActive ? "active" : ""}
                            >
                              {item.icon && <Icon css={styles.itemIcon} icon={item.icon} />}
                              <div>{item.name}</div>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
          </div>
        </Collapsible>
      )}
    </>
  );
};
