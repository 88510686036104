import { colors, letterSpacings, lineHeights, mediaQuery, spacing } from "../../../core";
import { css, keyframes } from "@emotion/react";

const visibilityAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const drawerWidth = 304;
const styles = {
  hideOnDesktop: () =>
    css({
      display: "block",
      [mediaQuery.desktopOrLarger]: {
        display: "none",
      },
    }),
  firstBar: () =>
    css({
      width: drawerWidth,
      display: "flex",
      justifyContent: "space-between",
      padding: spacing.baseUnitMobile2,
      paddingBottom: 0,
      color: colors.headlineGrey.hex,
    }),
  headerWrapper: () =>
    css({
      width: drawerWidth,
      display: "flex",
      flexDirection: "column",
      borderBottom: `1px solid ${colors.lightGrey.hex}`,
    }),
  navItems: () =>
    css({
      color: colors.headlineGrey.hex,
      letterSpacing: letterSpacings.body.cat,
      lineHeight: lineHeights.body.cat,
      "&.last-of-type": {
        marginBottom: "20px",
      },
    }),
  drawer: (orientationRight) =>
    css({
      width: drawerWidth,
      backgroundColor: colors.white.hex,
      flexShrink: 0,
      transform: orientationRight ? "translateX(100%)" : "translateX(-100%)",
      position: "fixed",
      top: 0,
      overflow: "scroll",
      zIndex: 1010,
      left: orientationRight ? "auto" : 0,
      right: orientationRight ? 0 : "auto",
      bottom: 0,
      height: "100vh",
      transition: "transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)",
      [mediaQuery.desktopOrLarger]: {
        display: "none",
      },
    }),
  rollOut: () =>
    css({
      transform: "translateX(0)",
    }),
  fullscreenFade: () =>
    css({
      backgroundColor: colors.darkTransparent.hex,
      display: "block",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      height: "100vh",
      animation: `${visibilityAnimation} 250ms ease`,
      animationFillMode: "forwards",
      overflowY: "hidden",
      zIndex: 1000,

      [mediaQuery.desktopOrLarger]: {
        display: "none",
      },
    }),
};

export { styles };
