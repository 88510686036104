import { LogoutApi } from "./logout-api";
import { ApiConfig } from "utils/backend-api/api-config";
import { Log, buildLogEntityWithoutRequest, formatMessage } from "utils/log";
import userStore from "stores/user.store";

const logout = async () => {
  const logoutApi = new LogoutApi(ApiConfig.buildBackendUrlForClient());

  try {
    await logoutApi.postLogout();
  } catch (error) {
    Log.error(buildLogEntityWithoutRequest(formatMessage(error), error));
  } finally {
    userStore.clear();
  }
};

export const logoutUtil = {
  logout,
};
