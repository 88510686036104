import { css, keyframes } from "@emotion/react";
import { colors, dimension, mediaQuery } from "../../core";

const visibilityAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const styles = {
  fullscreenFade: (isBehindModal: boolean) =>
    css({
      backgroundColor: colors.darkTransparent.hex,
      display: "block",
      position: "fixed",
      top: isBehindModal ? 0 : dimension.header.height,
      left: 0,
      right: 0,
      height: "100vh",
      animation: `${visibilityAnimation} 250ms ease`,
      animationFillMode: "forwards",
      overflowY: "hidden",
      zIndex: isBehindModal ? 998 : -1,

      [mediaQuery.smallerThanTablet]: {
        display: isBehindModal ? "initial" : "none",
      },
    }),
};

export { styles };
