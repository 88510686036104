import { ApiRoutes } from "../../../utils/backend-api/api-routes";
import { BackendApi } from "../../../utils/backend-api/backend-api";

export class LogoutApi {
  public backendApi: BackendApi;

  constructor(backendUrl: string) {
    this.backendApi = new BackendApi(backendUrl);
  }

  public postLogout() {
    return this.backendApi.postRequest(ApiRoutes.LOGOUT);
  }
}
