import { mediaQuery, colors, spacing } from "../../../core/styles";
import { css } from "@emotion/react";

export const styles = {
  navigationWrapper: () =>
    css({
      display: "none",
      width: "100%",
      [mediaQuery.desktopOrLarger]: {
        display: "flex",
        width: "fit-content",
      },
    }),
  naviLinks: () =>
    css({
      display: "flex",
      flexDirection: "row",
      listStyle: "none",
      margin: 0,
      padding: 0,
      position: "relative",
      right: "unset",
      top: "0",
      flex: 1,
      justifyContent: "space-between",
      [mediaQuery.desktopOrLarger]: {
        justifyContent: "start",
        alignItems: "center",
      },
    }),
  fadedOnHover: (separator: boolean) =>
    css({
      display: "flex",
      flexDirection: "row",
      columnGap: !separator ? spacing.baseUnitMobile3 : 0,
      listStyle: "none",
      transition: "color 0.3s ease",
      [mediaQuery.desktopOrLarger]: {
        "&> li > a": {
          padding: `${spacing.baseUnitMobile3} 0`,
        },
        "&:hover": {
          "&> li:not(:hover)": {
            "&> a": {
              color: colors.stdTextGrey.hex,
              span: {
                color: colors.stdTextGrey.hex,
              },
            },
            svg: {
              color: colors.stdTextGrey.hex,
              fill: colors.stdTextGrey.hex,
            },
          },
        },
      },
    }),
  withSeparator: () =>
    css({
      [mediaQuery.desktopOrLarger]: {
        "li > a": {
          margin: `0 ${spacing.baseUnitMobile2}`,
        },
        "li:not(:last-of-type)::after": {
          content: `""`,
          position: "absolute",
          top: "50%",
          right: 0,
          width: "1px",
          height: "24px",
          transform: "translateY(-50%)",
          background: colors.lightGrey.hex,
        },
      },
    }),
  logoWrapper: () =>
    css({
      paddingRight: `${spacing.baseUnitMobile4}`,
      width: "195px",
    }),
};
