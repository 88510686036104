import React from "react";
import { useBodyScrollLock } from "./helpers/useBodyScrollLock";
import { styles } from "./BackShadow.styles";

export interface BackShadowProps {
  isBehindModal?: boolean;
}

export const BackShadow: React.FC<BackShadowProps> = ({ isBehindModal }) => {
  useBodyScrollLock();
  return <div css={styles.fullscreenFade(isBehindModal)} />;
};
