import { useLayoutEffect } from "react";

export const useBodyScrollLock = () => {
  useLayoutEffect(() => {
    document.body.style["overflow-y"] = "hidden";
    return () => {
      document.body.style["overflow-y"] = "auto";
    };
  }, []);
};
