import React from "react";
import { styles } from "./NavbarItemTopBar.styles";

const NavbarItemTopBar: React.FC<{
  children: React.ReactElement;
  name: string;
  testSelector?: string;
  isMobile?: boolean;
}> = ({ children, name, testSelector, isMobile = false }) => {
  return (
    <li css={styles.root()} key={`nav-list-item-desktop-${name}`}>
      {React.cloneElement(children, {
        topBarStyling: true,
        testSelector: testSelector ? testSelector : children.props.testSelector,
        isMobile: isMobile,
      })}
    </li>
  );
};

export default NavbarItemTopBar;
