import { colors, fontSizes, spacing } from "../../../../core/styles";
import { css } from "@emotion/react";

const styles = {
  root: (isBordered) =>
    css({
      color: colors.white.hex,
      flexDirection: "column",
      justifyContent: "left",
      alignItems: "center",
      display: "flex",
      borderTop: isBordered && `1px solid ${colors.lightGrey.hex}`,

      "&> a": {
        color: colors.headlineGrey.hex,
        width: "100%",
        cursor: "pointer",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textTransform: "uppercase",
        fontWeight: 400,
        fontSize: fontSizes.body.horse,
        flex: 1,
        textAlign: "left",
        padding: `${spacing.baseUnitMobile2} ${spacing.baseUnitMobile4}`,
        display: "block",
        position: "relative",
        alignItems: "center",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "none",
        },
        "&.active": {
          fontWeight: 700,
        },
      },
    }),
};

export { styles };
