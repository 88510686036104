import React from "react";
import { styles } from "./NavbarItemSideMenu.styles";

const NavbarItemSideMenu: React.FC<{ children: React.ReactElement; name: string; isBordered?: boolean }> = ({
  children,
  name,
  isBordered,
}) => {
  return (
    <li key={`nav-list-mob-item-${name}`} css={styles.root(isBordered)}>
      {children}
    </li>
  );
};

export default NavbarItemSideMenu;
