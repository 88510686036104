import React, { useMemo } from "react";
import { Icon, Icons } from "../../../../elements/Icon";
import { MegaMenu } from "../../MegaMenu";
import { NavbarDropdown } from "../../NavbarDropdown";
import { ItemTypes, NavbarItemProps, NavItemOptions } from "../NavbarItem";
import { styles } from "../NavbarItem.styles";

type DesktopNavbarItemProps = {
  isDropdown: boolean;
  setOpenMenu: (open: boolean) => void;
  openMenu: boolean;
  openDropdownMenu: boolean;
  optionsWithDefault: NavItemOptions;
  anchorElement: React.MutableRefObject<any>;
};

export const DesktopNavbarItem: React.FC<DesktopNavbarItemProps & NavbarItemProps> = ({
  isActive,
  testSelector,
  link,
  onClick,
  icon,
  title,
  isDropdown,
  activeSubItem,
  dropdownItems = [],
  megaMenuColumns = [],
  optionsWithDefault,
  customElement,
  openDropdownMenu,
  openMenu,
  setOpenMenu,
  anchorElement,
  isMobile = false,
}) => {
  const displayName = icon ? <Icon icon={icon} css={styles.menuItemIconWrapper} /> : title;

  const mergedNavItemClasses = useMemo(() => {
    const classes = [];
    if (isActive) {
      classes.push("active");
    }
    if (optionsWithDefault.type === ItemTypes.MegaMenu || optionsWithDefault.type === ItemTypes.Dropdown) {
      classes.push("dropdown");
      if (openMenu) {
        classes.push("open");
      }
    }
    return classes.join(" ");
  }, [openMenu, isActive]);

  return (
    <>
      <a
        data-testid={testSelector}
        className={mergedNavItemClasses}
        href={link || null}
        ref={anchorElement}
        onClick={(e) => {
          setOpenMenu(!openMenu);
          if (link) onClick(e, link);
        }}
        key={`top-bar-nav-item-${testSelector}`}
      >
        <div suppressHydrationWarning data-navitem={title || ""}>
          {isDropdown && !icon && activeSubItem ? activeSubItem : displayName}
        </div>
        {isDropdown && <Icon icon={Icons.arrowThin} css={styles.arrowIcon(openDropdownMenu)} />}
      </a>
      {!isMobile && isDropdown && (dropdownItems.length > 0 || megaMenuColumns.length > 0) && (
        <div css={styles.hideOnMobile}>
          {optionsWithDefault.type == ItemTypes.Dropdown ? (
            <NavbarDropdown
              anchorElement={anchorElement}
              dropdownItems={dropdownItems}
              menuTitle={title}
              open={openMenu}
              setOpen={setOpenMenu}
              customSubTitleElement={customElement?.dropdownMenu}
            />
          ) : (
            <MegaMenu
              setOpen={setOpenMenu}
              open={openMenu}
              megaMenuColumns={megaMenuColumns}
              anchorElement={anchorElement}
            />
          )}
        </div>
      )}
    </>
  );
};
