import { colors, mediaQuery, content, spacing, dimension, zIndex } from "../../core/styles";
import { css } from "@emotion/react";

const headerHeightMobile = dimension.header.mobileHeight;
const headerHeightDesktop = dimension.header.height;

export const styles = {
  header: () =>
    css({
      backgroundColor: colors.warmGrey.hex,
      transition: "top 0.3s ease-in-out",
      right: 0,
      left: 0,
      position: "relative",
      zIndex: zIndex.header.wrapper,
      alignItems: "stretch",
      ":hover": {
        top: 0,
      },
      ".headroom": {
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        backgroundColor: colors.warmGrey.hex,
        boxShadow: "0 1px 4px 0 rgba(0,0,0,0.3)",
      },
      ".headroom--unfixed": {
        position: "relative",
        transform: "translateY(0)",
      },
      ".headroom--scrolled": {
        transition: "transform 200ms ease-in-out",
      },
      ".headroom--unpinned": {
        position: "fixed",
        transform: "translateY(-100%)",
      },
      ".headroom--pinned": {
        position: "fixed",
        transform: "translateY(0%)",
      },
    }),
  headerContentContainer: (hideNavInHeader?: boolean) =>
    css({
      maxWidth: `${content.width}px`,
      display: "flex",
      padding: `${spacing.baseUnitMobile2}`,
      justifyContent: "space-between",
      alignItems: "center",
      maxHeight: `${headerHeightMobile}px`,
      height: `${headerHeightMobile}px`,
      [mediaQuery.largerThanMobile]: {
        maxHeight: `${headerHeightDesktop}px`,
        height: `${headerHeightDesktop}px`,
        boxSizing: "border-box",
      },
      [mediaQuery.desktopOrLarger]: {
        padding: hideNavInHeader ? `${spacing.baseUnitMobile3}` : `0 ${spacing.baseUnitMobile3}`,
        margin: "auto",
      },
    }),
  overflowComponentStyling: () =>
    css({
      display: "none",
      backgroundColor: colors.warmGrey.hex,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      [mediaQuery.desktopOrLarger]: {
        display: "block",
      },
    }),
  logoExampleStyle: () =>
    css({
      display: "flex",
      alignItems: "center",
      padding: 0,
      [mediaQuery.desktopOrLarger]: {
        padding: `24px 0`,
      },
    }),
};
