// Deprecated file, use  customRouteMap, useRoutes etc
import { UrlQueryParameters } from "./urlQueryParameters";
import { routesUtil } from "../../domain/Routing/utils/routesUtil";

const customRoutes = routesUtil.getRoutes();

export const Routes = {
  HOME: "/", //would always be a match in useTranslateableRoutes() -> useSlug(); used by sessionUtil, pricing-response util and frontend-url-builder
  PAYMENT_CHECKOUT: "/payment/checkout",
  PAYMENT_PAY: "/payment/pay",
  PARTNER_PACKAGES: "/partner-packages",
  SOCIAL_LOGIN: "/redirect-social-login",
  SOCIAL_LOGIN_PURCHASE: "/redirect-social-login-payment",
  ACCOUNT: "/account", //still needed for loginUtil
  ACCOUNT_LOGIN: "/account/login",
  PAYMENT_REDIRECT: "/payment/redirect",
  PAYMENT_THANK_YOU: "/payment/thank-you",
  REPORT_CREDIT: "/report-credit",
  VHR: "/vhr",
  STATUS: "/status/v1", //used by server
  BLOG: "/blog", //used by frontend-url-builder
  BLOG_CATEGORY: "/blog/category",
  accountLoginWithErrorKey: (error: string): string =>
    `${Routes.ACCOUNT_LOGIN}?${UrlQueryParameters.ERROR_KEY}=${error}`,
  thankYouWithTransactionId: (transactionId: string): string =>
    `${Routes.PAYMENT_THANK_YOU}?${UrlQueryParameters.TRANSACTION_ID}=${transactionId}`,
  thankYouWithReportId: (reportId: string): string =>
    `${Routes.PAYMENT_THANK_YOU}?${UrlQueryParameters.REPORT_ID}=${reportId}&credits=true`,
  vhr: (transactionId: string): string => `${Routes.VHR}?${UrlQueryParameters.TRANSACTION_ID}=${transactionId}`,
  vhrReport: (reportId: string): string => `${Routes.VHR}?${UrlQueryParameters.REPORT_ID}=${reportId}`,
  blogPost: (slug: string): string => `${customRoutes.blog}/${slug}`,
  newsPost: (postSlug: string, locale?: string): string => {
    const slug = "press";
    const customRoute = routesUtil.getCustomRouteForLocale(slug, locale);
    return `${customRoute}/${postSlug}`;
  }, //used by sitemap
  category(slug: string): string {
    return `${Routes.BLOG_CATEGORY}/${slug}`;
  },
  paymentPayWithQuery: (queryToAppend: string): string => `${Routes.PAYMENT_PAY}?${queryToAppend}`,
  reactivateReportPayment: (vin: string, encryptedVinreg: boolean): string =>
    `${customRoutes.reactivate}?${UrlQueryParameters.VIN}=${encodeURIComponent(vin)}&${
      UrlQueryParameters.ENCRYPTED_VINREG
    }=${encodeURIComponent(encryptedVinreg)}`,
  paymentRedirect(transactionId: string): string {
    return `${Routes.PAYMENT_REDIRECT}?${UrlQueryParameters.TRANSACTION_ID}=${transactionId}`;
  },
  homeWithRedirectParameter: (redirectOrigin: string): string => {
    return `${Routes.HOME}?redirect="${redirectOrigin}"`;
  },
  accountLoginWithRedirect: (redirect: string): string =>
    `${customRoutes.accountLogin}?${UrlQueryParameters.REDIRECT}=${redirect}`,
};
