import { useRef } from "react";
import { styles } from "./Collapsible.style";

type CollapsibleProps = {
  show: boolean;
};

const Collapsible: React.FC<React.PropsWithChildren<CollapsibleProps>> = ({ show = false, children }) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const height = wrapperRef?.current && wrapperRef.current?.clientHeight;

  return (
    <div css={[show ? styles.open(height) : styles.closed, styles.collapseWrapper(!!height)]}>
      <div ref={wrapperRef}>{children}</div>
    </div>
  );
};

export default Collapsible;
