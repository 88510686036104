import React, { ReactNode } from "react";
import { styles } from "./MenuItem.styles";
import { Icon } from "../../../elements/Icon";

export interface ItemProps {
  onClick?: (event) => void;
  children: ReactNode;
  icon?: string;
}

export const MenuItem: React.FC<ItemProps> = ({ children, onClick, icon }: ItemProps) => {
  return (
    <div onClick={onClick} css={styles.root}>
      {icon && <Icon icon={icon} />}
      {children}
    </div>
  );
};
