import { linkUtil } from "../../../utils/i18n/link-util";

export const SocialLoginMethods = {
  APPLE: "apple",
};

export const getSocialLoginRedirectUri = (route: string) => {
  const host = window.location.host;
  const protocol = window.location.protocol;
  const baseUrl = `${protocol}//${host}`;
  return `${baseUrl}${linkUtil.i18nLink(route)}`;
};
