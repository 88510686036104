import { colors, fontSizes, dimension, mediaQuery } from "../../../../core/styles";
import { css } from "@emotion/react";
import { keyframes } from "@emotion/css";

const activeMainNavigationAnimation = keyframes`
  0% { height: 0; }
  100% { height: 3px; }
`;

const sideArrowSize = 24;
const openDropdownIndicatorSize = 13;

const styles = {
  root: () =>
    css({
      justifyContent: "left",
      alignItems: "center",
      display: "flex",
      backgroundColor: colors.transparent.hex,
      maxWidth: "300px",
      position: "relative",
      "&> a": {
        cursor: "pointer",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textTransform: "uppercase",
        fontWeight: 400,
        fontSize: fontSizes.body.horse,
        flex: 1,
        alignItems: "center",
        textDecoration: "none",
        display: "flex",
        color: colors.white.hex,
        width: "initial",
        position: "relative",
        transition: "color 250ms ease",
        [mediaQuery.desktopOrLarger]: {
          minHeight: dimension.header.height,
        },
        [mediaQuery.smallerThanTablet]: {
          padding: 0,
        },
        span: {
          color: colors.white.hex,
        },
        svg: {
          width: `${sideArrowSize}px`,
          height: `${sideArrowSize}px`,
        },
        "&.active": {
          fontWeight: "inherit",
          "&::after": {
            content: `""`,
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            backgroundColor: colors.green.hex,
            animation: `${activeMainNavigationAnimation} 250ms ease`,
            animationFillMode: "forwards",
          },
        },
        "&::after": {
          height: 0,
        },
        "&.dropdown": {
          fontWeight: "inherit",
          "&::after": {
            content: `""`,
            position: "absolute",
            left: `calc(50% - ${sideArrowSize}px)`,
            bottom: 0,
            transform: "translateY(100%)",
            transition: "transform 200ms ease",
            width: 0,
            height: 0,
            border: `${openDropdownIndicatorSize}px solid transparent`,
            borderBottom: `${openDropdownIndicatorSize}px solid ${colors.white.hex}`,
            zIndex: 2,
          },
          "&.open": {
            "&::after": {
              transform: "translateY(0)",
            },
          },
        },
      },
    }),
};

export { styles };
