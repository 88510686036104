import { linkUtil } from "utils/i18n/link-util";

const isCurrentPage = (url: string, routerPath?: string): boolean => {
  const router = routerPath || "/";
  return router == linkUtil.i18nLink(url);
};

const isParentOfCurrentPage = (parentUrl: string, routerPath?: string): boolean => {
  const currentPath = routerPath || "/";
  const parentPath = linkUtil.i18nLink(parentUrl);

  return currentPath.startsWith(parentPath);
};

export const navigationUtil = {
  isCurrentPage,
  isParentOfCurrentPage,
};
