import { EnvironmentVariables } from "../config/environment-variables";

export class ApiConfig {
  public static buildBackendUrlForClient() {
    const protocol = ApiConfig.getProtocol(EnvironmentVariables.endpointHttpsFromClient());
    const host = EnvironmentVariables.endpointHostFromClient();
    const port = EnvironmentVariables.endpointPortFromClient();
    const version = EnvironmentVariables.endpointVersionFromClient();

    return `${protocol}://${host}:${port}/api/v${version}`;
  }

  public static buildBackendUrlForNode() {
    const protocol = ApiConfig.getProtocol(EnvironmentVariables.endpointHttpsFromNode());
    const host = EnvironmentVariables.endpointHostFromNode();
    const port = EnvironmentVariables.endpointPortFromNode();
    const version = EnvironmentVariables.endpointVersionFromNode();

    return `${protocol}://${host}:${port}/api/v${version}`;
  }

  private static getProtocol(isHttps) {
    return isHttps === "true" ? `https` : `http`;
  }
}
