import React, { useRef } from "react";
import { Icon } from "../../../elements/Icon";
import { BackShadow } from "../../../elements/BackShadow";
import { styles } from "./MegaMenu.styles";
import useClickAway from "../../../hooks/useClickAway";

type MegaMenuColumnItem = {
  link: string;
  onClick?: (string, event) => void;
  icon?: string;
  name: string;
  testSelector?: string;
  description?: string;
  isActive?: boolean;
  isHidden?: boolean;
};

export type MegaMenuColumn = {
  title?: string;
  items: MegaMenuColumnItem[];
};

type Props = {
  open: boolean;
  megaMenuColumns: MegaMenuColumn[];
  setOpen: (boolean) => void;
  anchorElement: any;
};

const MegaMenu: React.FC<Props> = ({ open, megaMenuColumns = [], anchorElement, setOpen }) => {
  const ref = useRef();
  const refContent = useRef<HTMLDivElement>();
  const height = refContent.current?.clientHeight;

  const handleClose = (event) => {
    if (anchorElement.current && anchorElement.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useClickAway(ref, handleClose, !open);
  return (
    <>
      <div ref={ref}>
        <div css={styles.fullScreenContainer(open, height)}>
          <div ref={refContent} css={styles.columnsContainer}>
            {megaMenuColumns.map((column, index) => {
              return (
                <div key={`mega-menu-column-${index}`} css={styles.menuColumn}>
                  {column?.title && <div css={styles.categoryTitle}>{column.title}</div>}
                  {column?.items.map((item) => {
                    return (
                      <a
                        hidden={item.isHidden}
                        key={`mega-menu-column-item-${item.name}`}
                        href={item.link}
                        onClick={(e) => {
                          item?.onClick(e, item.link || "/");
                          setOpen(false);
                        }}
                        data-testid={item?.testSelector}
                      >
                        <div>
                          <div css={styles.linkTitle}>
                            {item?.icon && <Icon css={styles.menuIcon} icon={item.icon} />}
                            <div data-navitem={item.name}>{item.name}</div>
                          </div>
                          {item?.description && <p css={styles.linkDescription}>{item?.description}</p>}
                        </div>
                      </a>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {open && <BackShadow />}
    </>
  );
};

export default MegaMenu;
