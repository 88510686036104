import axios from "axios";

import { EApiRequestError, EApiRequestError as ApiReqErr, EHttpStatus, httpStatusMapper } from "./error-types";
import BackendApiError from "./backend-api-error";
import { Log, buildLogEntity, formatMessage } from "../log";
import { BackendRequestBuilder, httpMethods } from "./backend-request-builder";
import userStore from "../../stores/user.store";

export class BackendApi {
  private readonly backendRequestBuilder: BackendRequestBuilder;

  constructor(url: string) {
    this.backendRequestBuilder = new BackendRequestBuilder(url);
  }

  public postRequest(url: string, body?: any, params?: any, timeout?: number): Promise<any> {
    return this.request(url, params, timeout, "POST", body);
  }

  public putRequest(url: string, body?: any, params?: any, timeout?: number): Promise<any> {
    return this.request(url, params, timeout, "PUT", body);
  }

  public requestHtml(url: string) {
    return this.request(url, null, null, null, null, "text/html");
  }

  public async request<T>(
    url: string,
    params?: any,
    timeout?: number,
    method?: httpMethods,
    body?: JSON,
    mediaType?: string
  ) {
    const config = this.backendRequestBuilder.buildRequestConfig(url, method, mediaType, params, timeout, body);
    const startTime = performance.now();
    try {
      const res = await axios.request(config);
      const durationInMs = performance.now() - startTime;

      Log.info(
        buildLogEntity(
          formatMessage("method", config.method, "url:", `${config.baseURL}${config.url}`),
          config,
          res,
          durationInMs
        )
      );
      return res.data as T;
    } catch (error) {
      const durationInMs = performance.now() - startTime;
      Log.error(buildLogEntity(formatMessage(error), config, error, durationInMs));
      throw this.getErrorToThrow(error);
    }
  }

  private getErrorToThrow(error) {
    if (error.message === "Network Error") {
      return new BackendApiError(ApiReqErr.NetworkError);
    }

    const status = error?.response?.status ? Number(error.response.status) : null;
    if (status === EHttpStatus.Unauthorized) {
      this.clearUserStoreOnUnauthorized();
    }

    if (typeof status === "number") {
      if (error.response.data) {
        if (status === EHttpStatus.FailedDependency) {
          return new BackendApiError(JSON.stringify(error.response.data), status);
        }
        return new BackendApiError(httpStatusMapper.mapResponseMessageToErrorMessage(error.response.data), status);
      }
      return new BackendApiError(httpStatusMapper.mapToApiRequestError(status) || EApiRequestError.Unknown, status);
    }

    return error;
  }

  private clearUserStoreOnUnauthorized() {
    userStore.clear();
  }
}
