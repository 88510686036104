import { useEffect } from "react";

export const useClickAway = (ref, callback, disabled = false) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !disabled && !ref.current.contains(event.target)) {
        callback(event);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};
