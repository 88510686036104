import React from "react";
import { NavbarItemTopBar } from "../NavbarDecorators";
import { NavbarItemProps } from "../NavbarItem/NavbarItem";
import { styles } from "./NavContainer.styles";

type NavContainerProps = {
  logo?: React.ReactNode;
  withSeparator?: boolean;
  children: React.ReactElement<NavbarItemProps>[];
};

const NavContainer: React.FC<NavContainerProps> = ({ logo, children, withSeparator = false }) => {
  return (
    <div css={styles.navigationWrapper()}>
      <div css={[styles.naviLinks(), withSeparator ? styles.withSeparator() : ""]}>
        {logo && <div css={styles.logoWrapper}>{logo}</div>}
        <ul css={styles.fadedOnHover(withSeparator)} suppressHydrationWarning>
          {children.map((navItem: React.ReactElement) => {
            return (
              <NavbarItemTopBar key={`nav-items-desktop-${navItem.props.title}`} name={navItem.props.title}>
                {navItem}
              </NavbarItemTopBar>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default NavContainer;
