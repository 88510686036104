import React from "react";
import { DropdownItem } from "..";
import { Icon } from "../../../../elements/Icon";
import DrawerWrapper, { DrawerOrientation } from "../../DrawerWrapper/DrawerWrapper";
import { styles } from "../NavbarItem.styles";

type MobileOwnMenuProps = {
  openMenu: boolean;
  setOpenMenu: (boolean) => void;
  title: string;
  dropdownItems: DropdownItem[];
  customElement: any;
};

export const MobileOwnMenu: React.FC<MobileOwnMenuProps> = ({
  openMenu,
  setOpenMenu,
  title,
  dropdownItems,
  customElement,
}) => {
  return (
    <DrawerWrapper
      open={openMenu}
      orientation={DrawerOrientation.right}
      onClose={() => setOpenMenu(false)}
      menuTitle={title}
      customSubTitleElement={customElement?.sideMenu}
    >
      <ul key={`subitem-menu-container-${title}`}>
        {dropdownItems &&
          dropdownItems.map((dropdownItem) => (
            <li key={`subitem-side-menu-${dropdownItem.label}`} css={[styles.sideSubmenu(), styles.sideMenuOwnItem()]}>
              <a
                href={dropdownItem?.link || ""}
                onClick={dropdownItem.click}
                data-testid={dropdownItem?.testSelector ? `${dropdownItem.testSelector}-mobile` : null}
              >
                {dropdownItem.icon && <Icon icon={dropdownItem.icon} />}
                {dropdownItem.label}
              </a>
            </li>
          ))}
      </ul>
    </DrawerWrapper>
  );
};
