import { css } from "@emotion/react";

const styles = {
  collapseWrapper: (shouldAnimate: boolean) =>
    css({
      width: "100%",
      transition: shouldAnimate && "height 0.3s ease",
      overflow: "hidden",
    }),
  open: (height) =>
    css({
      height: height ? `${height}px` : "auto",
    }),
  closed: () =>
    css({
      height: 0,
    }),
};

export { styles };
