import { css } from "@emotion/react";

export const styles = {
  iconsStyle: () =>
    css({
      marginTop: "4px",
      width: "20px",
      height: "20px",
    }),
  leftItemContainer: () =>
    css({
      marginBottom: "40px",
    }),
};
